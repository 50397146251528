export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import('@/views/Chat.vue'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404.vue'),
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('@/views/Login.vue'),
  // },
  // {
  //   path: '/reset-password',
  //   name: 'reset-password',
  //   component: () => import('@/views/ResetPassword.vue'),
  // },
  // {
  //   path: '/otp',
  //   name: 'otp',
  //   component: () => import('@/views/Otp.vue'),
  // },
  // {
  //   path: '/blog-grid',
  //   name: 'blog-grid',
  //   component: () => import('@/views/BlogGrid.vue'),
  // },
  // {
  //   path: '/blog-listing',
  //   name: 'blog-listing',
  //   component: () => import('@/views/BlogListing.vue'),
  // },
  // {
  //   path: '/blog-details',
  //   name: 'blog-details',
  //   component: () => import('@/views/BlogDetails.vue'),
  // },
  // {
  //   path: '/terms',
  //   name: 'terms',
  //   component: () => import('@/views/Terms.vue'),
  // },
  {
    path: '/service',
    name: 'service',
    component: () => import('@/views/Service.vue'),
  },
  // {
  //   path: '/pricing',
  //   name: 'pricing',
  //   component: () => import('@/views/Pricing.vue'),
  // },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import('@/views/ContactUs.vue'),
  },
];