<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  created() {
    const search = window.location.search; // 获取 URL 中的查询字符串，如 "?foo=bar"
    const params = new URLSearchParams(search); // 使用 URLSearchParams 解析查询字符串
    let lang = params.get('lang'); // 获取参数 "foo" 的值
    let isApp = params.get('app')
    if (lang) {
      if (lang == 'zh-Hans') lang = 'zh-CN';
      if (lang == 'zh-Hant') lang = 'zh-HK';
      this.$i18n.locale = lang;
      localStorage.setItem('lang', lang)
      window.location.href = window.location.origin
    }
    if(isApp){
      localStorage.setItem('app', true)
    }

    // location.reload()
  }
}
</script>

<style></style>
