// for details please visit: https://glenthemes.github.io/iconsax


export default function init_iconsax() {
    document.querySelectorAll(".iconsax").forEach((iconsax) => {
        var TuT = iconsax.getAttribute("data-icon").toLowerCase().trim();
        //判断本地是否存在图标文件，不存在使用远程图标，存在使用本地
        import('@/assets/icons/'+TuT+'.svg')
        .then(svgModule => {
            fetch(svgModule.default)
            .then((svgRes) => {
                return svgRes.text();
            }).then((n_n) => {
                iconsax.innerHTML = n_n;
                if (iconsax.querySelectorAll("[http-equiv='Content-Security-Policy']").length) {
                    iconsax.innerHTML = "";
                }
            });
        })
        .catch(() => {
            fetch("https://glenthemes.github.io/iconsax/icons/" + TuT + ".svg")
            .then((n_n) => {
                return n_n.text();
            })
            .then((n_n) => {
                iconsax.innerHTML = n_n;
                if (iconsax.querySelectorAll("[http-equiv='Content-Security-Policy']").length) {
                    iconsax.innerHTML = "";
                }
            });
        });
       
    });
}
// window.addEventListener('load', () => {
//     init_iconsax();
// })

window.addEventListener('resize', init_iconsax);